@font-face {
font-family: '__circularXX_6854e3';
src: url(/_next/static/media/1891d161553798e5-s.p.woff) format('woff');
font-display: swap;
font-weight: 300;
font-style: normal;
}

@font-face {
font-family: '__circularXX_6854e3';
src: url(/_next/static/media/68d478a0668473cd-s.p.woff) format('woff');
font-display: swap;
font-weight: normal;
font-style: normal;
}

@font-face {
font-family: '__circularXX_6854e3';
src: url(/_next/static/media/2d801bcab707e0b6-s.p.woff) format('woff');
font-display: swap;
font-weight: 450;
font-style: normal;
}

@font-face {
font-family: '__circularXX_6854e3';
src: url(/_next/static/media/9afefee1d7416b38-s.p.woff) format('woff');
font-display: swap;
font-weight: bold;
font-style: normal;
}@font-face {font-family: '__circularXX_Fallback_6854e3';src: local("Arial");ascent-override: 94.93%;descent-override: 24.35%;line-gap-override: 0.00%;size-adjust: 100.60%
}.__className_6854e3 {font-family: '__circularXX_6854e3', '__circularXX_Fallback_6854e3'
}.__variable_6854e3 {--CircularXX: '__circularXX_6854e3', '__circularXX_Fallback_6854e3'
}

